@import '../utils/variables.css';

.table {
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.table td {
  padding: var(--spacing-x-small);
  line-height: var(--line-height-text);
  vertical-align: top;
}

.table tr:not(:last-child) > td {
  border-bottom: var(--border-width-thin) solid var(--light-gray);
}

.table th {
  padding: var(--spacing-x-small);
  border-bottom: var(--border-width-thick) solid var(--light-gray);
  font-weight: var(--font-weight-bold);
  line-height: var(--line-height-text);
  text-align: left;
  vertical-align: top;
}

.table--striped > tbody > tr:nth-child(even) {
  background-color: var(--gray-lighter);
}
