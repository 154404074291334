@import '../utils/variables.css';

/* -----------------------------------------------------------------------------
 * Fonts
 * -------------------------------------------------------------------------- */

/* -----------------------------------------------------------------------------
 * Links
 * -------------------------------------------------------------------------- */

a {
  color: var(--link-color);
  text-decoration: none;
}

a:hover,
b:focus {
  color: var(--link-hover-color);
}

/* -----------------------------------------------------------------------------
 * Lists
 * -------------------------------------------------------------------------- */

ol,
ul,
dl {
  margin: 0;
  padding: 0;
  list-style: none;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}
