/* ==========================================================================
   #NUBBIN
   ========================================================================== */

.o-nubbin--top::before,
.o-nubbin--top::after {
  content: '';
  position: absolute;
  top: calc(var(--square-tooltip-nubbin) / 2 * -1);
  left: 47%;
  width: var(--square-tooltip-nubbin);
  height: var(--square-tooltip-nubbin);
  margin-left: calc(var(--square-tooltip-nubbin) / 2 * -1);
  transform: rotate(45deg);
  background-color: inherit;
}

.o-nubbin--top::after {
  z-index: -1;
  box-shadow: -1px -1px 0 0 rgba(0, 0, 0, 0.16);
}

@media (max-width: 47.9375em) {
  .o-nubbin--top::before,
  .o-nubbin--top::after {
    left: 82%;
  }
}
