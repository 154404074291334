@import '../utils/variables.css';

body *:focus-visible {
  outline: 1px solid #147ec1;
}

body *:focus:not(:focus-visible) {
  outline: none;
}
.wrapper {
  height: 100%;
  /* overflow: hidden; */
  background-color: #ffffff;
  width: 100%;
}

.wrapper .slide-routes {
  flex: 1;
  padding: 0 1.5rem;
}

/* .wrapper .item {
  position: relative;
  height: 100vh;
  width: 100%;
  margin: 0 auto;
  padding-top: var(--topbar-height);
} */

.main__container {
  position: absolute;
  left: 60px;
  width: calc(100% - 60px);
  height: 100vh;
  /* overflow: hidden; */
  background: #f7f8fb;
}

.main__container .page__content {
  width: 100%;
  height: 100vh;
  overflow: auto;
}

.main__container .main__content {
  height: 100vh;
}

.header__logout {
  line-height: var(--height-header);
}

.header__logout-link {
  color: #ffffff;
  text-decoration: underline;
}

.header__logout-link:hover {
  color: #ffffff;
  text-decoration: none;
}

.page-head {
  position: relative;
  z-index: 2;
  padding: 0.625rem;
}

.page-title {
  display: inline-block;
  font-weight: 700;
  text-transform: uppercase;
}

.page-actions button:not(:last-child) {
  margin-right: 0.25rem;
}

.content {
  height: 100%;
}

.top-bar:not(.hidden) + .content {
  height: calc(100% - 2.25rem);
}

.column {
  padding-bottom: 0.625rem;
}

.column .scroll {
  box-shadow: 0 0.0625rem 0.125rem 0 rgba(0, 0, 0, 0.2);
}

.column .scroll-content {
  padding: 1rem;
  background-color: #ffffff;
}

.column-section {
  margin-bottom: 1.5rem;
}

.block {
  margin-bottom: 1rem;
  padding: 0.625rem;
}

.block--highlight {
  background-color: #f8f8f8;
  box-shadow: 0 0.0625rem 0.125rem 0 rgba(0, 0, 0, 0.2);
}

.preview-pushmail::before,
.preview-pushmail::after {
  content: ' ';
  display: table;
}

.preview-pushmail::after {
  clear: both;
}

.preview-pushmail__title-icon,
.preview-pushmail__title,
.preview-pushmail__subtitle {
  display: inline-block;
  vertical-align: middle;
}

.preview-pushmail-content {
  width: 100%;
  height: 100%;
  min-height: 600px;
  padding-top: 50px;
  padding-left: 30px;
}

.preview-pushmail__title-icon {
  display: none;
  width: 35px;
  font-size: 1rem;
  line-height: 0;
}

.preview-pushmail__title {
  padding-right: 10px;
  font-size: 1.5rem;
  font-weight: 700;
  text-transform: uppercase;
}

.preview-pushmail__subtitle {
  margin-right: 0.375rem;
  padding-right: 40px;
  font-size: 1.5rem;
  font-weight: 500;
  letter-spacing: 0.05rem;
}

.preview-pushmail__group {
  position: relative;
  margin-top: 20px;
  overflow: hidden;
}

.preview-pushmail__group--title {
  max-width: 415px;
}

.preview-pushmail__label {
  display: block;
  margin-bottom: 0.25rem;
  color: #000000;
  font-weight: 700;
}

.preview-pushmail__group--logo .preview-pushmail__label {
  margin-bottom: 15px;
  padding-bottom: 6px;
  border-bottom: 1px solid #d3d3d3;
}

.preview-pushmail__shadow::after {
  content: '';
  position: absolute;
  z-index: -1;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30px;
  box-shadow: none;
  color: rgba(199, 152, 157, 0.6);
}

.preview-pushmail__logo-list {
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.preview-pushmail__logo-list .press-review-template__label {
  top: 30px;
}

.preview-pushmail__logo-list .press-review-template__bullet {
  width: 42px;
  height: 25px;
}

.preview-pushmail__logo-list__item:nth-child(3n + 1) {
  margin-right: -100%;
  margin-left: 0;
  clear: both;
}

.preview-pushmail__logo-list__item:nth-child(3n + 2) {
  margin-right: -100%;
  margin-left: 33.89831%;
  clear: none;
}

.preview-pushmail__logo-list__item:nth-child(3n + 3) {
  margin-right: -100%;
  margin-left: 67.79661%;
  clear: none;
}

.preview-pushmail__logo-list__item {
  width: 32.20339%;
  margin-bottom: 1.69492%;
  float: left;
  overflow: hidden;
}

.preview-pushmail__footer {
  margin-top: 30px;
  float: right;
}

@media (max-width: 600px) {
  .preview-pushmail__formgroup {
    width: 100%;
  }

  .wrapper {
    overflow: visible;
  }

  .wrapper .item {
    padding-right: 5px;
    padding-left: 5px;
    padding-bottom: var(--bottombar-height);
  }

  .page-title {
    margin-bottom: 0.5rem;
    font-size: 1.25rem;
  }
}

@media (min-width: 768px) {
  .page-title {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2rem;
  }

  .page-actions {
    position: relative;
    top: 50%;
    float: right;
    transform: translateY(-50%);
  }

  .page-head + .content {
    height: calc(100% - 5rem);
  }

  .lightbox--preview-pushmail .lightbox__container {
    width: 740px;
  }

  .preview-pushmail__title-icon {
    display: inline-block;
  }

  .preview-pushmail__head {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .preview-pushmail__logo-list__item {
    width: 23.72881%;
    float: left;
  }

  .preview-pushmail__logo-list__item:nth-child(4n + 1) {
    margin-right: -100%;
    margin-left: 0;
    clear: both;
  }

  .preview-pushmail__logo-list__item:nth-child(4n + 2) {
    margin-right: -100%;
    margin-left: 25.42373%;
    clear: none;
  }

  .preview-pushmail__logo-list__item:nth-child(4n + 3) {
    margin-right: -100%;
    margin-left: 50.84746%;
    clear: none;
  }

  .preview-pushmail__logo-list__item:nth-child(4n + 4) {
    margin-right: -100%;
    margin-left: 76.27119%;
    clear: none;
  }

  .preview-pushmail__footer {
    right: 26px;
    margin-top: 60px;
  }
}

.routefadeIn {
  animation: 0.5s routefadeIn forwards;
}

.routefadeOut {
  animation: 0.5s routefadeOut forwards;
}

@keyframes routefadeIn {
  from {
    opacity: 0;
    transform: translate(-20px, 0);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

@keyframes routefadeOut {
  from {
    opacity: 1;
    transform: translate(0px, 0px);
  }
  to {
    transform: translate(-20px, 0);
    opacity: 0;
  }
}

@media (max-width: 768px) {
  .wrapper .slide-routes {
    padding: 0;
  }
  .preview-pushmail__formgroup {
    width: 49.5%;
  }
}

@media (min-width: 1280px) {
  .left-column:not(.hidden) + .main {
    margin-left: var(--width-sidebar);
  }

  .page-head {
    height: 5rem;
  }

  .lightbox--preview-pushmail .lightbox__container {
    width: 960px;
  }
  .preview-pushmail__title-icon {
    width: 45px;
    font-size: 1.5rem;
  }
  .preview-pushmail__title {
    font-size: 2.188rem;
  }
  .preview-pushmail__subtitle {
    font-size: 2.188rem;
  }

  .preview-pushmail__logo-list__item {
    width: 15.25424%;
    float: left;
  }

  .preview-pushmail__logo-list__item:nth-child(6n + 1) {
    margin-right: -100%;
    margin-left: 0;
    clear: both;
  }

  .preview-pushmail__logo-list__item:nth-child(6n + 2) {
    margin-right: -100%;
    margin-left: 16.94915%;
    clear: none;
  }

  .preview-pushmail__logo-list__item:nth-child(6n + 3) {
    margin-right: -100%;
    margin-left: 33.89831%;
    clear: none;
  }

  .preview-pushmail__logo-list__item:nth-child(6n + 4) {
    margin-right: -100%;
    margin-left: 50.84746%;
    clear: none;
  }

  .preview-pushmail__logo-list__item:nth-child(6n + 5) {
    margin-right: -100%;
    margin-left: 67.79661%;
    clear: none;
  }

  .preview-pushmail__logo-list__item:nth-child(6n + 6) {
    margin-right: -100%;
    margin-left: 84.74576%;
    clear: none;
  }
}
/* Routes transitions animations */
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}
.fade-exit {
  opacity: 1;
  transform: translate(-20px);
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}
/* END Routes transitions animations */

/*IE*/
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .new-scrollbar {
    /* ie scrollbar color properties */
    scrollbar-base-color: #efefef;
    scrollbar-face-color: #d6d1e5;
    scrollbar-3dlight-color: #666666;
    scrollbar-highlight-color: #666666;
    scrollbar-track-color: #f3f1f8;
    scrollbar-arrow-color: #d6d1e5;
    scrollbar-shadow-color: #f3f1f8;
  }
}
