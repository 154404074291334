/* ==========================================================================
   #SIZING
   ========================================================================== */

:root {
  --height-input: 2.5rem;
  --height-pill: 1.625rem;
  --square-icon-large-boundary: 3rem;
  --square-tooltip-nubbin: 1rem;
}
