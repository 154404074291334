/* ==========================================================================
   #BORDER
   ========================================================================== */

.u-border--top {
  border-top: var(--border-width-thin) solid var(--color-border);
}

.u-border--right {
  border-right: var(--border-width-thin) solid var(--color-border);
}

.u-border--left {
  border-left: var(--border-width-thin) solid var(--color-border);
}

.u-border--bottom {
  border-bottom: var(--border-width-thin) solid var(--color-border);
}
