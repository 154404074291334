@import '../utils/variables.css';

.react-tabs__tab-list {
  display: flex;
  margin: var(--spacing-none);
  padding: var(--spacing-none);
  list-style: none;
}

.react-tabs__tab {
  display: inline-block;
  flex: 1;
  height: var(--line-height-tab);
  padding-right: var(--spacing-medium);
  padding-left: var(--spacing-medium);
  overflow: hidden;
  border-bottom: var(--border-width-thick) solid transparent;
  letter-spacing: 0.0625rem;
  line-height: var(--line-height-tab);
  text-overflow: ellipsis;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  cursor: pointer;
}
.react-tabs__tab:first-child {
  border-radius: 7px 0 0 0;
}

.react-tabs__tab:last-child {
  border-radius: 0 7px 0 0;
}

@media (max-width: 600px) {
  .react-tabs__tab {
    padding-left: 0;
  }
}

.react-tabs__tab--selected {
  font-weight: 700;
  border-bottom: 4px solid var(--palette-secondary-main);
}
.react-tabs__tab-panel {
  padding-top: var(--spacing-small);
  padding-bottom: var(--spacing-medium);
}

.react-tabs__tab-panel:not(.react-tabs__tab-panel--selected) {
  display: none;
}

.react-tabs.vertical {
  display: inline-block;
  width: 100%;
  min-height: 475px;
}

.react-tabs.vertical > .react-tabs__tab-list {
  border: 0;
  border-right: 1px solid var(--silver);
}

.react-tabs.vertical > .react-tabs__tab-list .react-tabs__tab {
  width: 100%;
  color: var(--white);
  font-weight: 500;
}

.react-tabs.vertical > .react-tabs__tab-panel {
  margin-left: var(--spacing-small);
  padding: 0;
}

.react-tabs.horizontal {
  display: flex;
  flex-direction: column;
  height: 500px;
}

.react-tabs.horizontal > .react-tabs__tab-list {
  display: flex;
  flex: 1;
  flex-direction: row;
  border: 0;
}

.react-tabs.horizontal > .react-tabs__tab-list .react-tabs__tab--selected {
  background: var(--primary-color);
}

.react-tabs.horizontal > .react-tabs__tab-list .react-tabs__tab {
  width: 100%;
  background: var(--secondary-color);
  color: var(--white);
  font-weight: 500;
}

.react-tabs.horizontal > .react-tabs__tab-panel {
  flex: 10;
  height: 100%;
  padding: 0;
  overflow-y: hidden;
}
