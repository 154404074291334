@import '../utils/variables.css';

/* stylelint-disable selector-no-universal */
*,
*::before,
*::after {
  box-sizing: inherit;
}
/* stylelint-enable */

html {
  box-sizing: border-box;
  height: 100%;
  background: var(--body-bg);
  font-size: var(--font-size-root);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  height: 100%;
  margin: 0;
  color: var(--text-color);
  font-family: var(--font-family);
  font-size: var(--font-size-base);
  line-height: var(--line-height-text);
}

a,
button {
  cursor: pointer;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

img {
  max-width: 100%;
  height: auto;
}

tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: middle;
  padding: 5px;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

textarea {
  vertical-align: top;
}
