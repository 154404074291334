/* Fonts */
@font-face {
  font-family: KantarBrown;
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src:
    local('KantarBrown-Thin'),
    url('https://kantar-web-frontend.s3.eu-west-1.amazonaws.com/assets/fonts/KantarBrown-Thin.woff2')
      format('woff2'),
    url('https://kantar-web-frontend.s3.eu-west-1.amazonaws.com/assets/fonts/KantarBrown-Thin.woff')
      format('woff');
}
@font-face {
  font-family: KantarBrown;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src:
    local('KantarBrown-Light'),
    url('https://kantar-web-frontend.s3.eu-west-1.amazonaws.com/assets/fonts/KantarBrown-Light.woff2')
      format('woff2'),
    url('https://kantar-web-frontend.s3.eu-west-1.amazonaws.com/assets/fonts/KantarBrown-Light.woff')
      format('woff');
}
@font-face {
  font-family: KantarBrown;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src:
    local('KantarBrown-Regular'),
    url('https://kantar-web-frontend.s3.eu-west-1.amazonaws.com/assets/fonts/KantarBrown-Regular.woff2')
      format('woff2'),
    url('https://kantar-web-frontend.s3.eu-west-1.amazonaws.com/assets/fonts/KantarBrown-Regular.woff')
      format('woff');
}
@font-face {
  font-family: KantarBrown;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src:
    local('KantarBrown-Bold'),
    url('https://kantar-web-frontend.s3.eu-west-1.amazonaws.com/assets/fonts/KantarBrown-Bold.woff2')
      format('woff2'),
    url('https://kantar-web-frontend.s3.eu-west-1.amazonaws.com/assets/fonts/KantarBrown-Bold.woff')
      format('woff');
}
/* GENERIC */
@import './generic/generic.reset.css';

/* OBJECTS */
@import './objects/objects.nubbin.css';

/* UTILITIES */
@import './utilities/utilities.border.css';

/* OTHER */
@import './base/reboot.css';
@import './base/typography.css';
@import './utils/helpers.css';
@import './layout/main.css';
@import './layout/forms.css';

/* EXTERNAL COMPONENTS */
@import './components/react-tabs.css';
@import './components/table.css';
/* @import './components/template.css'; */
@import './components/sweetAlert.css';
