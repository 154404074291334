@import '../utils/variables.css';

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  color: inherit;
  font: inherit;
  line-height: inherit;
}

textarea {
  overflow: auto;
}

fieldset {
  min-width: 0;
  margin: 0;
  padding: 0;
  border: 0;
}

input[type='search'] {
  box-sizing: border-box;

  /* stylelint-disable property-no-vendor-prefix */
  -webkit-appearance: none;
  /* stylelint-enable property-no-vendor-prefix */
}

/* iOS "clickable elements" fix for role="button" */
[role='button'] {
  cursor: pointer;
}

/* -----------------------------------------------------------------------------
 * Labels
 * -------------------------------------------------------------------------- */

input[type='email'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='phone'],
input[type='text'] {
  border-radius: 0; /* iPad/iPhone */
}

.custom-checkbox {
  display: inline-block;
  position: relative;
  width: 1.25rem;
  height: 1.25rem;
  border-color: #9d9d9c;
  background-color: var(--white);
  color: var(--text-color);
  line-height: 0.875rem;
  vertical-align: middle;
  cursor: pointer;
  border-width: 2px;
  border-style: solid;
  border-radius: 2px;
}

.custom-checkbox:hover {
  box-shadow: 0 0 2px 0 rgba(42, 100, 150, 0.4);
}
.custom-checkbox input:checked + ::before {
  content: '';
  display: block;
  position: absolute;
  top: -0.1875rem;
  left: 0.0625rem;
  width: 0.875rem;
  height: 0.375rem;
  transform: rotate(-50deg);
  transform-origin: 100% 100% 0;
  border-bottom: 0.125rem solid var(--text-color);
  border-left: 0.125rem solid var(--text-color);
}

.custom-checkbox input:indeterminate + ::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 4px;
  transform: translate(-50%, -50%);
  background: var(--text-color);
}

.custom-label {
  display: inline-block;
  vertical-align: middle;
}

/* Custom select */

.customSelect {
  position: relative;
  margin: 0 auto;
  text-align: left;
}

.customSelect .selectedValue {
  cursor: pointer;
}

.customSelect .selectList {
  display: none;
  position: absolute;
  width: 100%;
}
.customSelect .selectList dl {
  width: auto !important;
}

.customSelect .select-open .selectList {
  display: block !important;
}

.customSelect dl,
dd {
  margin: 0;
}

.customSelect dd {
  position: relative;
  cursor: pointer;
}
select.custom {
  position: absolute;
  left: -999em;
}

/* Select custom */

.select-custom {
  position: relative;
  z-index: 100;
  margin-right: auto;
  margin-bottom: 10px;
  margin-left: auto;
}

.select-custom__value-wrap {
  cursor: pointer;
}

.select-custom__value {
  padding: 4px 35px 4px 5px;
  border-width: 1px;
  border-style: solid;
  background: var(--white);
  color: var(--black);
}

.select-custom__btn {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  text-align: center;
}

.select-custom:not(.is-open) .select-custom__btn::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  transform: translate(-50%, -50%);
  border-top: 5px solid #fff;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
}

.is-open .select-custom__btn {
  background: #2e2e2e !important;
}

.is-open .select-custom__btn::after {
  content: '\d7';
  font-size: 1.125rem;
  line-height: 1.6;
}

.select-custom__list {
  position: absolute;
  width: 100%;
  border-width: 0 1px 1px;
  border-style: solid;
  background: var(--white);
}

.select-custom:not(.is-open) .select-custom__list {
  display: none;
}

.select-custom__option {
  position: relative;
  padding: 2px 10px 2px 30px;
  color: var(--black);
  line-height: 1.6;
  cursor: default;
}

.select-custom__option:hover,
.select-custom__option.is-selected {
  font-weight: 700;
}
.select-custom__option.is-selected::before {
  content: '\e040';
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
  color: #f0b348;
  font-family: Custom, sans-serif;
  font-size: 0.75rem;
}

.select-custom__option:last-child {
  background-color: #efefef;
}

.select-custom__option:last-child::before {
  background: none;
}
.select-custom__option:last-child::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 10px;
  width: 0;
  height: 0;
  transform: translateY(-50%);
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid #000;
}

.select-custom__option:not(.is-selected):last-child::before {
  content: '\e007';
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
  color: var(--spanish-gray);
  font-family: Custom, sans-serif;
  font-size: 0.875rem;
}
