/* SETTINGS */
@import '../settings/settings.background-color.css';
@import '../settings/settings.border-color.css';
@import '../settings/settings.brand-color.css';
@import '../settings/settings.custom-color.css';
@import '../settings/settings.sizing.css';
@import '../settings/settings.spacing.css';
@import '../settings/settings.text-color.css';

:root {
  --primary-color: #ccc;
  --secondary-color: #eee;
  /* ---------------------------------------------------------------------------
   * LOGIN PAGE
   * ------------------------------------------------------------------------ */
  --color-primary: #9c1e8d;
  --color-box-shadow: #b7b7b7;
  --color-alert-success: #06be47;
  --background-color-input: #f3f5f6;
  --background-input-hover: #fff;
  --color-alert-error: #cd0a0a;
  --color-link: #21599d;
  /* ---------------------------------------------------------------------------
   * Background Color
   * ------------------------------------------------------------------------ */
  --color-background-alert: #d9edf7;
  --color-background-error: #e43411;
  --color-background-success: #06be47;
  --color-background-button-brand-disabled: #e0e5ee;
  --color-background-button-default: var(--white);
  --color-background-button-default-active: #eef1f6;
  --color-background-button-default-disabled: #fff;
  --color-background-button-default-focus: #f4f6f9;
  --color-background-button-default-hover: #f4f6f9;
  --color-background-button-destructive-active: #870500;
  --color-background-destructive: #c23934;
  --color-background-destructive-active: #870500;
  --color-background-destructive-hover: #a61a14;
  --color-background-input-active: #f7f7f7;
  --color-background-input-disabled: var(--gray-light);
  --color-background-notification-error: #f4e9e9;
  --color-background-notification-success: #f0f5ea;

  /* ---------------------------------------------------------------------------
   * Text Color
   * ------------------------------------------------------------------------ */

  --color-text-alert: #31708f;
  --color-text-button-brand-disabled: var(--white);
  --color-text-button-default: var(--color-text-link);
  --color-text-button-default-active: var(--color-text-link-active);
  --color-text-button-default-disabled: #d8dde6;
  --color-text-button-default-hover: var(--color-text-link-hover);
  --color-text-default: var(--jet);
  --color-text-error: #c23934;
  --color-text-icon-default: #54698d;
  --color-text-inverse: var(--white);
  --color-text-keyword: #4db948;
  --color-text-link: #0070d2;
  --color-text-link-active: #00396b;
  --color-text-link-disabled: #16325c;
  --color-text-link-focus: #005fb2;
  --color-text-link-hover: #005fb2;
  --color-text-weak: var(--text-color);
  --color-error: #e43411;
  --color-success: #06be47;

  /* ---------------------------------------------------------------------------
   * Border Color
   * ------------------------------------------------------------------------ */

  --color-border-error: #e43411;
  --color-border-success: #06be47;
  --color-border-button-brand-disabled: transparent;
  --color-border-button-default: #d8dde6;
  --color-border-destructive-active: #870500;
  --color-border-input-active: #1589ee;
  --color-border-input-disabled: #a8b7c7;

  /* ---------------------------------------------------------------------------
   * Font
   * ------------------------------------------------------------------------ */

  --font-family: KantarBrown, 'Roboto', 'Helvetica Neue', helvetica, arial,
    sans-serif;
  --font-family-base: 'Roboto', 'Helvetica Neue', helvetica, arial, sans-serif;
  --font-size-root: 16px;
  --font-size-base: 0.875rem;
  --font-weight-thin: 100;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-weight-black: 900;

  /* ---------------------------------------------------------------------------
   * Font Size
   * ------------------------------------------------------------------------ */

  --font-size-x-small: 0.625rem;
  --font-size-small: 0.75rem;
  --font-size-medium: 0.8125rem;
  --font-size-medium-a: 0.875rem;
  --font-size-medium-b: 0.9rem;
  --font-size-large: 1rem;
  --font-size-x-large: 1.25rem;
  --font-size-xx-large: 1.75rem;
  --font-size-xxx-large: 2.25rem;

  /* ---------------------------------------------------------------------------
   * Line Height
   * ------------------------------------------------------------------------ */

  --line-height-text: 1.428571429;
  --line-height-fulltext: 1.8;
  --line-height-reset: 1;
  --line-height-tab: 2.5rem;
  --line-height-button: 2.125rem;
  --line-height-button-small: 1.75rem;
  --line-height-heading: 1.25;

  /* ---------------------------------------------------------------------------
   * Spacing
   * ------------------------------------------------------------------------ */

  --border-none: 0;
  --border-width-thin: 1px;
  --border-width-thick: 2px;
  --spacing-none: 0;
  --spacing-xxx-small: 0.125rem;
  --spacing-xx-small: 0.25rem;
  --spacing-x-small: 0.5rem;
  --spacing-small: 0.75rem;
  --spacing-x-medium: 0.35rem;
  --spacing-medium: 1rem;
  --spacing-large: 1.5rem;
  --spacing-x-large: 2rem;
  --spacing-xx-large: 3rem;

  /* ---------------------------------------------------------------------------
   * Radius
   * ------------------------------------------------------------------------ */

  --border-radius-small: 0.125rem;
  --border-radius-medium: 0.25rem;
  --border-radius-large: 0.5rem;
  --border-radius-pill: 15rem;
  --border-radius-circle: 50%;

  /* ---------------------------------------------------------------------------
   * Sizing
   * ------------------------------------------------------------------------ */

  --size-xx-small: 6rem;
  --size-x-small: 12rem;
  --size-small: 15rem;
  --size-medium: 20rem;
  --size-large: 25rem;
  --size-x-large: 40rem;
  --size-xx-large: 60rem;
  --square-icon-utility-large: 1.5rem;
  --square-icon-medium-boundary: 2rem;
  --square-icon-medium-boundary-alt: 2.25rem;
  --square-icon-medium-content: 1rem;
  --square-icon-small-content: 0.75rem;
  --square-icon-x-small-content: 0.5rem;

  /* ---------------------------------------------------------------------------
   * Shadow
   * ------------------------------------------------------------------------ */

  --shadow-error: rgba(236, 61, 61, 0.901961) 0 0 1px;
  --shadow-success: rgba(94, 164, 0, 0.901961) 0 0 1px;
  --shadow-drag: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
  --shadow-drop-down: 0 2px 3px 0 rgba(0, 0, 0, 0.16);
  --shadow-button-focus: 0 0 3px #0070d2;

  /* ---------------------------------------------------------------------------
   * Time
   * ------------------------------------------------------------------------ */

  --duration-instantly: 0s;
  --duration-immediately: 0.05s;
  --duration-quickly: 0.1s;
  --duration-promptly: 0.2s;
  --duration-slowly: 0.4s;
  --duration-paused: 3.2s;

  /* ---------------------------------------------------------------------------
   * Z-index
   * ------------------------------------------------------------------------ */

  --z-index-notification: 10000;
  --z-index-modal: 9000;
  --z-index-overlay: 8000;
  --z-index-dropdown: 7000;
  --z-index-dialog: 6000;
  --z-index-popup: 5000;
  --z-index-default: 1;
  --z-index-deepdive: -99999;

  /* ---------------------------------------------------------------------------
   * Misc
   * ------------------------------------------------------------------------ */

  --height-header: 3.12rem;
  --height-footer: 3.125rem;
  --height-top-bar: 2.5rem;
  --height-top-bar-second: 2.5rem;
  --width-sidebar: 16.25rem;

  /* ---------------------------------------------------------------------------
   * Scaffolding
   * ------------------------------------------------------------------------ */

  --body-bg: #ffffff;
  --text-color: var(--jet);
  --link-color: #428bca;
  --link-hover-color: color(var(--link-color) blackness(15%));

  /* ---------------------------------------------------------------------------
   * Colors
   * ------------------------------------------------------------------------ */

  --white: #fff;
  --black: #000;
  --gray-lightest: #fdfdfd;
  --gray-lighter: #f8f8f8;
  --gray-light: #eee;
  --gray-medium-light: #e0e0e0;
  --light-gray: #d3d3d3;
  --silver: #c0c0c0;
  --light-silver: #ccc;
  --medium-gray: #bebebe;
  --dark-medium-gray: #a9a9a9;
  --spanish-gray: #989898;
  --gray: #808080;
  --dim-gray: #696969;
  --davy-gray: #555;
  --jet: #343434;
  --gray-dark: #424242;
  --gray-darker: #202325;
  --brand-success: #06be47;
  --brand-info: #5bc0de;
  --brand-warning: #f0ad4e;
  --brand-danger: #e43411;
  --selection-color: #f6e4c0;
  --kantar-color: #ff5800;
  --twitter-color: #55acee;
  --highlight-color: #fefe69;
  --color-border-destructive: #c23934;
  --color-border-destructive-hover: #a61a14;
  --violet-light: #f0e7f5;

  /* ---------------------------------------------------------------------------
   * Buttons
   * ------------------------------------------------------------------------ */
  --btn-default-border: var(--medium-gray);
  --btn-default-border-hover: var(--gray-dark);
  --btn-default-bg: var(--gray-lighter);
  --btn-default-bg-hover: var(--gray-dark);
  --btn-default-color: var(--text-color);
  --btn-default-color-hover: var(--white);
  --btn-success-bg: var(--brand-success);
  --btn-success-bg-hover: color(var(--btn-success-bg) blackness(30%));
  --btn-success-color: var(--white);
  --btn-warning-bg: var(--brand-warning);
  --btn-warning-bg-hover: color(var(--btn-warning-bg) lightness(-10%));
  --btn-warning-color: var(--white);
  --btn-danger-bg: var(--brand-danger);
  --btn-danger-bg-hover: color(var(--btn-danger-bg) lightness(55%));
  --btn-danger-color: var(--white);
  --btn-link-color: #0275d8;

  /* ---------------------------------------------------------------------------
   * Widget
   * ------------------------------------------------------------------------ */

  --widget-gutter: 15px;
  --widget-height: 275px;
  --widget-height-header: 40px;
  --widget-width: 275px;

  /* ---------------------------------------------------------------------------
   * Expanded
   * ------------------------------------------------------------------------ */

  --expanded-widget-height: 500px;

  /* ---------------------------------------------------------------------------
  * SearchBar
  * ------------------------------------------------------------------------ */

  --expanded-search-bar-width: 16.25rem;
  --collapsed-search-bar-width: 1rem;

  /* ---------------------------------------------------------------------------
   * Media Query
   * ------------------------------------------------------------------------ */

  @custom-media --min-sm (width >= 600px);
  @custom-media --min-md (width >= 768px);
  @custom-media --min-lg (width >= 1280px);
  @custom-media --min-xl (width >= 1900px);
  @custom-media --max-xs (width < 600px);
  @custom-media --max-sm (width < 600px);
  @custom-media --max-md (width < 768px);
  @custom-media --max-lg (width < 1280px);
  @custom-media --only-sm (width >= 600px) and (width < 768px);
  @custom-media --only-md (width >= 768px) and (width < 1280px);
  @custom-media --only-lg (width >= 1280px) and (width < 1900px);
  @custom-media --ipad-pro-p (min-device-width: 1024px) and (max-device-width: 1024px) and (min-device-height: 1366px) and (max-device-height: 1366px) and (min-width: 1024px) and (max-width: 1024px);
  @custom-media --monitoring-custom-width (width <= 71.9375em);
  @custom-media --monitoring-custom-img (width >= 768px) and (width <= 68em);
}
