/* ==========================================================================
   #BACKGROUND COLOR
   ========================================================================== */

:root {
  --color-background-alt: var(--white);
  --color-background-button-brand: #0070d2;
  --color-background-input: #f7f7f7;
  --color-background-inverse: #061c3f;
  --color-background-row-hover: var(--gray-light);
  --color-background-row-selected: var(--selection-color);
  --table-color-background-header: var(--gray-light);
}
