@import './variables.css';

/* -----------------------------------------------------------------------------
 * Floats
 * -------------------------------------------------------------------------- */
.clearfix::after {
  content: ' ';
  display: table;
  clear: both;
}

.pull--left {
  float: left !important;
}

.pull--right {
  float: right !important;
}

/* -----------------------------------------------------------------------------
 * Lists
 * -------------------------------------------------------------------------- */
.list-unstyled {
  padding-left: 0 !important;
  list-style: none !important;
}

/* -----------------------------------------------------------------------------
 * Position
 * -------------------------------------------------------------------------- */
.u-position--fixed {
  position: fixed !important;
}

.u-position--relative {
  position: relative !important;
}

.u-position--absolute {
  position: absolute !important;
}

.u-position--static {
  position: static !important;
}

.u-pin--top-right {
  top: 0 !important;
  right: 0 !important;
}

.u-pin--top-left {
  top: 0 !important;
  left: 0 !important;
}

.u-pin--bottom-right {
  right: 0 !important;
  bottom: 0 !important;
}

.u-pin--bottom-left {
  bottom: 0 !important;
  left: 0 !important;
}

/* -----------------------------------------------------------------------------
 * Scrollable
 * -------------------------------------------------------------------------- */
.scrollable--y {
  max-height: 100%;
  overflow-x: hidden !important;
  overflow-y: auto !important;
  -webkit-overflow-scrolling: touch;
}

.scrollable--x {
  max-width: 100%;
  overflow: hidden;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

/* -----------------------------------------------------------------------------
 * Sizing
 * -------------------------------------------------------------------------- */
.u-half-width {
  width: 50% !important;
}

.u-full-width {
  width: 100% !important;
}

.u-full-height {
  height: 100% !important;
  overflow: auto;
}

/* -----------------------------------------------------------------------------
 * Spacing
 * -------------------------------------------------------------------------- */

/* Margin */

.m-top--none {
  margin-top: var(--spacing-none) !important;
}

.m-right--none {
  margin-right: var(--spacing-none) !important;
}

.m-bottom--none {
  margin-bottom: var(--spacing-none) !important;
}

.m-left--none {
  margin-left: var(--spacing-none) !important;
}

.m-vertical--none {
  margin-top: var(--spacing-none) !important;
  margin-bottom: var(--spacing-none) !important;
}

.m-horizontal--none {
  margin-right: var(--spacing-none) !important;
  margin-left: var(--spacing-none) !important;
}

.m-around--none {
  margin: var(--spacing-none) !important;
}

.m-top--xxx-small {
  margin-top: var(--spacing-xxx-small) !important;
}

.m-right--xxx-small {
  margin-right: var(--spacing-xxx-small) !important;
}

.m-bottom--xxx-small {
  margin-bottom: var(--spacing-xxx-small) !important;
}

.m-left--xxx-small {
  margin-left: var(--spacing-xxx-small) !important;
}

.m-vertical--xxx-small {
  margin-top: var(--spacing-xxx-small) !important;
  margin-bottom: var(--spacing-xxx-small) !important;
}

.m-horizontal--xxx-small {
  margin-right: var(--spacing-xxx-small) !important;
  margin-left: var(--spacing-xxx-small) !important;
}

.m-around--xxx-small {
  margin: var(--spacing-xxx-small) !important;
}

.m-top--xx-small {
  margin-top: var(--spacing-xx-small) !important;
}

.m-right--xx-small {
  margin-right: var(--spacing-xx-small) !important;
}

.m-bottom--xx-small {
  margin-bottom: var(--spacing-xx-small) !important;
}

.m-left--xx-small {
  margin-left: var(--spacing-xx-small) !important;
}

.m-vertical--xx-small {
  margin-top: var(--spacing-xx-small) !important;
  margin-bottom: var(--spacing-xx-small) !important;
}

.m-horizontal--xx-small {
  margin-right: var(--spacing-xx-small) !important;
  margin-left: var(--spacing-xx-small) !important;
}

.m-around--xx-small {
  margin: var(--spacing-xx-small) !important;
}

.m-top--x-small {
  margin-top: var(--spacing-x-small) !important;
}

.m-right--x-small {
  margin-right: var(--spacing-x-small) !important;
}

.m-bottom--x-small {
  margin-bottom: var(--spacing-x-small) !important;
}

.m-left--x-small {
  margin-left: var(--spacing-x-small) !important;
}

.m-vertical--x-small {
  margin-top: var(--spacing-x-small) !important;
  margin-bottom: var(--spacing-x-small) !important;
}

.m-horizontal--x-small {
  margin-right: var(--spacing-x-small) !important;
  margin-left: var(--spacing-x-small) !important;
}

.m-around--x-small {
  margin: var(--spacing-x-small) !important;
}

.m-top--small {
  margin-top: var(--spacing-small) !important;
}

.m-right--small {
  margin-right: var(--spacing-small) !important;
}

.m-bottom--small {
  margin-bottom: var(--spacing-small) !important;
}

.m-left--small {
  margin-left: var(--spacing-small) !important;
}

.m-vertical--small {
  margin-top: var(--spacing-small) !important;
  margin-bottom: var(--spacing-small) !important;
}

.m-horizontal--small {
  margin-right: var(--spacing-small) !important;
  margin-left: var(--spacing-small) !important;
}

.m-around--small {
  margin: var(--spacing-small) !important;
}

.m-top--medium {
  margin-top: var(--spacing-medium) !important;
}

.m-right--medium {
  margin-right: var(--spacing-medium) !important;
}

.m-bottom--medium {
  margin-bottom: var(--spacing-medium) !important;
}

.m-left--medium {
  margin-left: var(--spacing-medium) !important;
}

.m-vertical--medium {
  margin-top: var(--spacing-medium) !important;
  margin-bottom: var(--spacing-medium) !important;
}

.m-horizontal--medium {
  margin-right: var(--spacing-medium) !important;
  margin-left: var(--spacing-medium) !important;
}

.m-around--medium {
  margin: var(--spacing-medium) !important;
}

.m-top--large {
  margin-top: var(--spacing-large) !important;
}

.m-right--large {
  margin-right: var(--spacing-large) !important;
}

.m-bottom--large {
  margin-bottom: var(--spacing-large) !important;
}

.m-left--large {
  margin-left: var(--spacing-large) !important;
}

.m-vertical--large {
  margin-top: var(--spacing-large) !important;
  margin-bottom: var(--spacing-large) !important;
}

.m-horizontal--large {
  margin-right: var(--spacing-large) !important;
  margin-left: var(--spacing-large) !important;
}

.m-around--large {
  margin: var(--spacing-large) !important;
}

.m-top--x-large {
  margin-top: var(--spacing-x-large) !important;
}

.m-right--x-large {
  margin-right: var(--spacing-x-large) !important;
}

.m-bottom--x-large {
  margin-bottom: var(--spacing-x-large) !important;
}

.m-left--x-large {
  margin-left: var(--spacing-x-large) !important;
}

.m-vertical--x-large {
  margin-top: var(--spacing-x-large) !important;
  margin-bottom: var(--spacing-x-large) !important;
}

.m-horizontal--x-large {
  margin-right: var(--spacing-x-large) !important;
  margin-left: var(--spacing-x-large) !important;
}

.m-around--x-large {
  margin: var(--spacing-x-large) !important;
}

.m-top--xx-large {
  margin-top: var(--spacing-xx-large) !important;
}

.m-right--xx-large {
  margin-right: var(--spacing-xx-large) !important;
}

.m-bottom--xx-large {
  margin-bottom: var(--spacing-xx-large) !important;
}

.m-left--xx-large {
  margin-left: var(--spacing-xx-large) !important;
}

.m-vertical--xx-large {
  margin-top: var(--spacing-xx-large) !important;
  margin-bottom: var(--spacing-xx-large) !important;
}

.m-horizontal--xx-large {
  margin-right: var(--spacing-xx-large) !important;
  margin-left: var(--spacing-xx-large) !important;
}

.m-around--xx-large {
  margin: var(--spacing-xx-large) !important;
}

/* Padding */
.p-top--none {
  padding-top: var(--spacing-none) !important;
}

.p-right--none {
  padding-right: var(--spacing-none) !important;
}

.p-bottom--none {
  padding-bottom: var(--spacing-none) !important;
}

.p-left--none {
  padding-left: var(--spacing-none) !important;
}

.p-vertical--none {
  padding-top: var(--spacing-none) !important;
  padding-bottom: var(--spacing-none) !important;
}

.p-horizontal--none {
  padding-right: var(--spacing-none) !important;
  padding-left: var(--spacing-none) !important;
}

.p-around--none {
  padding: var(--spacing-none) !important;
}

.p-top--xxx-small {
  padding-top: var(--spacing-xxx-small) !important;
}

.p-right--xxx-small {
  padding-right: var(--spacing-xxx-small) !important;
}

.p-bottom--xxx-small {
  padding-bottom: var(--spacing-xxx-small) !important;
}

.p-left--xxx-small {
  padding-left: var(--spacing-xxx-small) !important;
}

.p-vertical--xxx-small {
  padding-top: var(--spacing-xxx-small) !important;
  padding-bottom: var(--spacing-xxx-small) !important;
}

.p-horizontal--xxx-small {
  padding-right: var(--spacing-xxx-small) !important;
  padding-left: var(--spacing-xxx-small) !important;
}

.p-around--xxx-small {
  padding: var(--spacing-xxx-small) !important;
}

.p-top--xx-small {
  padding-top: var(--spacing-xx-small) !important;
}

.p-right--xx-small {
  padding-right: var(--spacing-xx-small) !important;
}

.p-bottom--xx-small {
  padding-bottom: var(--spacing-xx-small) !important;
}

.p-left--xx-small {
  padding-left: var(--spacing-xx-small) !important;
}

.p-vertical--xx-small {
  padding-top: var(--spacing-xx-small) !important;
  padding-bottom: var(--spacing-xx-small) !important;
}

.p-horizontal--xx-small {
  padding-right: var(--spacing-xx-small) !important;
  padding-left: var(--spacing-xx-small) !important;
}

.p-around--xx-small {
  padding: var(--spacing-xx-small) !important;
}

.p-top--x-small {
  padding-top: var(--spacing-x-small) !important;
}

.p-right--x-small {
  padding-right: var(--spacing-x-small) !important;
}

.p-bottom--x-small {
  padding-bottom: var(--spacing-x-small) !important;
}

.p-left--x-small {
  padding-left: var(--spacing-x-small) !important;
}

.p-vertical--x-small {
  padding-top: var(--spacing-x-small) !important;
  padding-bottom: var(--spacing-x-small) !important;
}

.p-horizontal--x-small {
  padding-right: var(--spacing-x-small) !important;
  padding-left: var(--spacing-x-small) !important;
}

.p-around--x-small {
  padding: var(--spacing-x-small) !important;
}

.p-top--small {
  padding-top: var(--spacing-small) !important;
}

.p-right--small {
  padding-right: var(--spacing-small) !important;
}

.p-bottom--small {
  padding-bottom: var(--spacing-small) !important;
}

.p-left--small {
  padding-left: var(--spacing-small) !important;
}

.p-vertical--small {
  padding-top: var(--spacing-small) !important;
  padding-bottom: var(--spacing-small) !important;
}

.p-horizontal--small {
  padding-right: var(--spacing-small) !important;
  padding-left: var(--spacing-small) !important;
}
.p-horizontal-x-small {
  padding-right: var(--spacing-x-small) !important;
  padding-left: var(--spacing-x-small) !important;
}

.p-around--small {
  padding: var(--spacing-small) !important;
}

.p-top--medium {
  padding-top: var(--spacing-medium) !important;
}

.p-right--medium {
  padding-right: var(--spacing-medium) !important;
}

.p-bottom--medium {
  padding-bottom: var(--spacing-medium) !important;
}

.p-left--medium {
  padding-left: var(--spacing-medium) !important;
}

.p-vertical--medium {
  padding-top: var(--spacing-medium) !important;
  padding-bottom: var(--spacing-medium) !important;
}

.p-horizontal--medium {
  padding-right: var(--spacing-medium) !important;
  padding-left: var(--spacing-medium) !important;
}

.p-around--medium {
  padding: var(--spacing-medium) !important;
}

.p-top--large {
  padding-top: var(--spacing-large) !important;
}

.p-right--large {
  padding-right: var(--spacing-large) !important;
}

.p-bottom--large {
  padding-bottom: var(--spacing-large) !important;
}

.p-left--large {
  padding-left: var(--spacing-large) !important;
}

.p-vertical--large {
  padding-top: var(--spacing-large) !important;
  padding-bottom: var(--spacing-large) !important;
}

.p-horizontal--large {
  padding-right: var(--spacing-large) !important;
  padding-left: var(--spacing-large) !important;
}

.p-around--large {
  padding: var(--spacing-large);
}

.p-top--x-large {
  padding-top: var(--spacing-x-large) !important;
}

.p-right--x-large {
  padding-right: var(--spacing-x-large) !important;
}

.p-bottom--x-large {
  padding-bottom: var(--spacing-x-large) !important;
}

.p-left--x-large {
  padding-left: var(--spacing-x-large) !important;
}

.p-vertical--x-large {
  padding-top: var(--spacing-x-large) !important;
  padding-bottom: var(--spacing-x-large) !important;
}

.p-horizontal--x-large {
  padding-right: var(--spacing-x-large) !important;
  padding-left: var(--spacing-x-large) !important;
}

.p-around--x-large {
  padding: var(--spacing-x-large) !important;
}

.p-top--xx-large {
  padding-top: var(--spacing-xx-large) !important;
}

.p-right--xx-large {
  padding-right: var(--spacing-xx-large) !important;
}

.p-bottom--xx-large {
  padding-bottom: var(--spacing-xx-large) !important;
}

.p-left--xx-large {
  padding-left: var(--spacing-xx-large) !important;
}

.p-vertical--xx-large {
  padding-top: var(--spacing-xx-large) !important;
  padding-bottom: var(--spacing-xx-large) !important;
}

.p-horizontal--xx-large {
  padding-right: var(--spacing-xx-large) !important;
  padding-left: var(--spacing-xx-large) !important;
}

.p-around--xx-large {
  padding: var(--spacing-xx-large) !important;
}

/* -----------------------------------------------------------------------------
 * Text
 * -------------------------------------------------------------------------- */
/* Weight */
.text-thin {
  font-weight: var(--font-weight-thin) !important;
}

.text-light {
  font-weight: var(--font-weight-light) !important;
}

.text-medium {
  font-weight: var(--font-weight-medium) !important;
}

.text-regular {
  font-weight: var(--font-weight-regular) !important;
}

.text-bold {
  font-weight: var(--font-weight-bold) !important;
}

.text-italic {
  font-style: italic !important;
}

/* Alignment */
.text-align--left {
  text-align: left !important;
}

.text-center,
.text-align--center {
  text-align: center;
}

.text-right,
.text-align--right {
  text-align: right !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.text-break {
  word-wrap: break-word !important;
  hyphens: auto !important;
  overflow-wrap: break-word !important;
}

/* Transformation */
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

/* Contextual colors */
.text-muted {
  color: var(--gray) !important;
}

.text-success {
  color: var(--brand-success) !important;
}
.text-success a,
.text-success a:hover {
  color: var(--brand-success) !important;
}

.text-info {
  color: var(--brand-info) !important;
}

.text-info a,
.text-info a:hover {
  color: var(--brand-info) !important;
}

.text-warning {
  color: var(--brand-warning) !important;
}

.text-warning a,
.text-warning a:hover {
  color: var(--brand-warning) !important;
}

.text-danger {
  color: var(--brand-danger) !important;
}

.text-danger a,
.text-danger a:hover {
  color: var(--brand-danger) !important;
}

.text-highlight {
  background-color: var(--highlight-color) !important;
}

.text-keyword {
  background-color: transparent !important;
  color: var(--color-text-keyword) !important;
  font-weight: var(--font-weight-bold) !important;
}

.text-color--default {
  color: var(--color-text-default) !important;
}

.text-color--error {
  color: var(--color-text-error) !important;
}

/* -----------------------------------------------------------------------------
 * Visibility
 * -------------------------------------------------------------------------- */

/* Hide Show */
.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.show--inline-block {
  display: inline-block !important;
}

.show--inline-flex {
  display: inline-flex !important;
}

.show--block {
  display: block !important;
}

/* Hidden Visible */
.hidden,
[hidden] {
  display: none !important;
  visibility: hidden !important;
}

.visible {
  display: initial !important;
  visibility: visible !important;
}

/* Collapsed Expanded */
.is-collapsed {
  height: 0 !important;
  overflow: hidden !important;
}

.is-expanded {
  height: auto !important;
  overflow: visible !important;
}

.u-assistive-text {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  clip: rect(0 0 0 0) !important;
  border: 0 !important;
}

/* -----------------------------------------------------------------------------
 * Utilities responsive
 * -------------------------------------------------------------------------- */

.u-no-border {
  border: none;
}

.u-hidden-xs-up {
  display: none !important;
}

@media (max-width: calc(var(--breakPoints-xs) * 1px)) {
  .u-hidden-xs-down {
    display: none !important;
  }
}

@media (min-width: calc(var(--breakPoints-sm) * 1px)) {
  .u-hidden-sm-up {
    display: none !important;
  }
}

@media (max-width: calc(var(--breakPoints-sm) * 1px)) {
  .u-hidden-sm-down {
    display: none !important;
  }
}

@media (min-width: calc(var(--breakPoints-md) * 1px)) {
  .u-hidden-md-up {
    display: none !important;
  }
}

@media (max-width: calc(var(--breakPoints-md) * 1px)) {
  .u-hidden-md-down {
    display: none !important;
  }
}

@media (min-width: calc(var(--breakPoints-lg) * 1px)) {
  .u-hidden-lg-up {
    display: none !important;
  }
}

@media (max-width: calc(var(--breakPoints-lg) * 1px)) {
  .u-hidden-lg-down {
    display: none !important;
  }
}

@media (min-width: calc(var(--breakPoints-xl) * 1px)) {
  .u-hidden-xl-up {
    display: none !important;
  }
}

.u-hidden-xl-down {
  display: none !important;
}

/* -----------------------------------------------------------------------------
 * Display
 * -------------------------------------------------------------------------- */
.u-display--inline {
  display: inline !important;
}

.u-display--block {
  display: block !important;
}

.u-display--inline-block {
  display: inline-block !important;
}

.u-display--table {
  display: table !important;
}

.u-display--table-row {
  display: table-row !important;
}

.u-display--table-cell {
  display: table-cell !important;
}

/* -----------------------------------------------------------------------------
 * Misc
 * -------------------------------------------------------------------------- */
.u-center-block {
  display: block !important;
  margin-right: auto !important;
  margin-left: auto !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.element-clickable {
  cursor: pointer;
}

/* -----------------------------------------------------------------------------
 * Borders
 * -------------------------------------------------------------------------- */
.border-rounded {
  border-radius: 100%;
}

/* -----------------------------------------------------------------------------
 * Borders Separator TOP
 * -------------------------------------------------------------------------- */

.top-separator {
  padding-top: 10px;
  border-top: 1px solid #c0c0c0;
  box-shadow: 0 1px #fff inset;
}

/* -----------------------------------------------------------------------------
 * INPUTS
 * -------------------------------------------------------------------------- */

.hide-numeric-input-arrows::-webkit-outer-spin-button,
.hide-numeric-input-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}

.margin-left-auto {
  margin-left: auto;
}
