/* ============================================================================
   #BORDER COLOR
   ========================================================================== */

:root {
  --color-border: #c0c0c0;
  --color-border-input: #c0c0c0;
  --color-border-selection: #0070d2;
  --color-border-separator-alt: #d8dde6;
}
