/* ==========================================================================
   #RESET
   ========================================================================== */

abbr[title] {
  text-decoration: none;
  cursor: help;
}

abbr[title],
fieldset,
hr {
  border: 0;
}

table {
  border-spacing: 0;
}

caption,
th,
td {
  /* text-align: left; */
}

strong {
  font-weight: 700;
}

* {
  outline: none;
}
