/* ==========================================================================
   #CUSTOM COLOR
   ========================================================================== */

:root {
  --color-balanced: #ffc30a;
  --color-negative: #e53410;
  --color-neutral: #808080;
  --color-notanalyzed: #e1e1e1;
  --color-positive: #07be47;
}
